<template>
    <div class="table-wrapper">
        <template v-if="isShowTable">
            <!-- Toolbox Start -->
            <div v-if="isShowTableTool" class="table-tool">
                <TableTools
                    v-if="toolbox.length > 0"
                    :disabled="isLoading"
                    toolName="表格工具"
                    v-model:tableWidth="viewportWidth"
                >
                    <template v-slot:tools="slotProps">
                        <!-- 新增頁面客製工具， Html和CSS格式請對照toolboxOption -->
                        <template
                            v-for="(key, index) in newToolKeys"
                            :key="index"
                            class="key"
                        >
                            <slot
                                @click="setToolModal(slotProps, key)"
                                :name="key"
                            />
                        </template>
                        <div
                            v-for="(option, index) in toolboxOptions"
                            :key="index"
                            class="tool-option"
                            :class="option.class"
                            @click="setToolModal(slotProps, option.key)"
                        >
                            <div class="icon" :class="`${option.class}-icon`" />
                            <div
                                v-if="
                                    !isLoading &&
                                    option.key === 'columnsSetting' &&
                                    hiddenCount > 0
                                "
                                class="hidden-columns-bubble"
                            >
                                {{ hiddenCount }}
                            </div>
                            <div>{{ option.label }}</div>
                        </div>
                        <Excel
                            v-if="toolbox.includes('excel')"
                            :fileName="excelConfig.fileName"
                            :columns="columns"
                            :filters="excelConfig.filters"
                            :rows="excelData"
                            :infos="excelConfig.infos"
                            :descriptions="excelConfig.descriptions"
                            :disabled="isLoading"
                        />
                    </template>
                </TableTools>

                <div class="column-controller-container">
                    <Sort
                        v-if="shouldShowSort"
                        v-model:sort="sort"
                        v-model:sortBy="sortBy"
                        :columns="columns"
                        :disabled="isLoading"
                    />
                    <Filter
                        v-if="shouldShowFilter"
                        v-model="isShowFilterModal"
                        v-model:filterCheckedList="filterCheckedList"
                        v-model:filterColumnKey="filterColumnKey"
                        :isFilterSetList="isFilterSetList"
                        :columns="columns"
                        :rows="rows"
                        :disabled="isLoading"
                    />
                </div>
            </div>
            <Zooming
                v-show="isFullSizeTable"
                v-model:modelIsFullSize="isFullSizeTable"
                v-model:modelTableScaleRatio="tableScaleRatio"
            />
            <ColumnsSettingModal
                ref="columnSetting"
                v-model="isShowColumnsSettingModal"
                v-model:columnConfig="columnConfigValue"
                v-model:hiddenColumns="hiddenCount"
                :defaultColumns="setDefaultColumns"
                :columnsMode="columnsMode"
                :hasColumnSetting="toolbox.includes('columnSetting')"
            />
            <!-- Toolbox End -->
            <template v-if="rowWidth > viewportWidth">
                <div
                    class="table-arrow-icon left"
                    :class="{ disabled: !canScrollLeft }"
                    @click="scrollLeft()"
                />
                <div
                    class="table-arrow-icon right"
                    :class="{ disabled: !canScrollRight }"
                    @click="scrollRight()"
                />
            </template>
            <div
                class="table-container"
                :class="{ 'full-size': isFullSizeTable, card: isUseCard }"
                :style="TableCssProps"
                ref="tableColumn"
            >
                <div
                    ref="table"
                    class="table"
                    :class="{ loading: isLoading }"
                    @scroll="setViewportPosition()"
                >
                    <!-- 客製化傳入內容的架構 -->
                    <div
                        v-if="$slots['mobile-header'] && !isLoading"
                        class="mobile-header"
                    >
                        <slot></slot>
                    </div>
                    <div
                        v-if="mobileToolTips.length > 0 && !isLoading"
                        class="mobile-header"
                    >
                        <p v-for="(tip, index) in mobileToolTips" :key="index">
                            ・<span>{{ tip }}</span>
                        </p>
                    </div>
                    <Row
                        ref="headerRow"
                        :row="headerRow"
                        :tips="tips"
                        :columns="columns"
                        :isHeader="true"
                        :isLoading="isLoading"
                        :isRecursive="isRecursive"
                        :isFilterSetList="isFilterSetList"
                        v-model:headerCollapsedList="childCollapsedList"
                        v-model:filterColumnKey="filterColumnKey"
                        v-model:isShowFilterModal="isShowFilterModal"
                    >
                        <template
                            v-for="(_, slot) in $slots"
                            v-slot:[slot]="props"
                        >
                            <slot
                                :name="slot"
                                :isHeader="true"
                                :row="props.row"
                            />
                        </template>
                    </Row>
                    <div class="table-body" ref="tableBody">
                        <Row
                            v-for="(row, rowIndex) in tableShowData"
                            :key="rowIndex"
                            :row="row"
                            :columns="columns"
                            :isLoading="isLoading"
                            :isRecursive="isRecursive"
                            :isFocusRow="isFocusRow"
                            v-model:isCollapsed="childCollapsedList[rowIndex]"
                        >
                            <template
                                v-for="(_, slot) in $slots"
                                v-slot:[slot]="props"
                            >
                                <slot
                                    :name="slot"
                                    :isHeader="false"
                                    :row="props.row"
                                />
                            </template>
                        </Row>
                    </div>
                    <div
                        v-if="isFilteredEmpty"
                        class="filtered-empty-row"
                        v-text="'欄位過濾後無資料，請重新設定過濾條件'"
                    />
                </div>
            </div>
            <Description
                v-if="descriptions.length > 0 && !isLoading"
                :descriptions="descriptions"
            />
            <slot name="table-footer" />
            <Pagination
                v-if="isPagination"
                :isLoading="isLoading"
                :dataCount="pagination.rowCount"
                :currentPage="pagination.currentPage"
                :totalPage="paginationTotalPage"
                @go-to-page="setPaginationGoToPage"
                @change-per-page="setPaginationPerPage"
                class="pagination"
            />
        </template>
        <template v-else-if="isShowSearchMessage"> <SearchGuide /> </template>
        <template v-else>
            <NoData :emptyMessage="emptyMessage" />
        </template>
    </div>
</template>

<script>
import _ from 'lodash'
import { lazyLoadComponent } from '@/assets/javascripts/util'
const Excel = lazyLoadComponent('components/Excel')
const Pagination = lazyLoadComponent('components/Pagination')
const Description = lazyLoadComponent('components/Description')
const NoData = lazyLoadComponent('components/NoData')
const SearchGuide = lazyLoadComponent('components/ SearchGuide')
import Row from '@/components/table/Row'
const Sort = lazyLoadComponent('components/table/Sort')
const Filter = lazyLoadComponent('components/table/Filter')
const TableTools = lazyLoadComponent('components/table/TableTools')
const Zooming = lazyLoadComponent('components/table/Zooming')
const ColumnsSettingModal = lazyLoadComponent(
    'components/table/ColumnsSettingModal'
)

export default {
    name: 'Table',
    components: {
        Row,
        Sort,
        Pagination,
        Filter,
        NoData,
        TableTools,
        Excel,
        Zooming,
        ColumnsSettingModal,
        Description,
        SearchGuide
    },
    props: {
        isInModal: {
            type: Boolean,
            default: false
        },
        isPagination: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isRecursive: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        columnConfig: {
            type: Object,
            default: function () {
                return {}
            }
        },
        tips: {
            type: String,
            default: ''
        },
        rows: {
            type: Array,
            default: function () {
                return []
            }
        },
        sortDefault: {
            type: Object,
            default: function () {
                return {
                    sort: 'normal',
                    sortBy: ''
                }
            }
        },
        toolbox: {
            type: Array,
            default: function () {
                return []
            }
        },
        isUseCard: {
            type: Boolean,
            default: true
        },
        excelConfig: {
            type: Object,
            default: function () {
                return {
                    fileName: '',
                    filters: {},
                    descriptions: [],
                    infos: []
                }
            }
        },
        emptyMessage: {
            type: String || undefined,
            default: undefined
        },
        isFocusRow: {
            type: Boolean,
            default: false
        },
        descriptions: {
            type: Array,
            default: function () {
                return []
            }
        },
        hiddenColumnDefault: {
            type: Array,
            default: function () {
                return []
            }
        },
        newToolKeys: {
            type: Array,
            default: function () {
                return []
            }
        },
        columnsMode: {
            type: String,
            default: null
        },
        isFixedHeader: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        setPaginationPerPage: function (val) {
            this.pagination.perPage = parseInt(val)
        },
        setPaginationGoToPage: function (val) {
            this.pagination.currentPage = parseInt(val)
        },
        getPaginationData: function (rows) {
            const p = this.pagination
            const rowStartIndex = (p.currentPage - 1) * p.perPage
            const rowEndIndex = rowStartIndex + p.perPage
            this.pagination.rowCount = rows.length
            return rows.slice(rowStartIndex, rowEndIndex)
        },
        getSortedData: function (rows) {
            rows.forEach((row) => {
                if (row._detail && row._detail.length > 0) {
                    row._detail = this.getSortedData(row._detail)
                }
            })
            return rows.sort((rowA, rowB) => {
                const a = rowA[this.sortBy]
                const b = rowB[this.sortBy]
                if (typeof a === 'string' && typeof b === 'string') {
                    return this.sort === 'asc'
                        ? a.localeCompare(b)
                        : b.localeCompare(a)
                } else if (typeof a === 'number' && typeof b === 'number') {
                    return this.sort === 'asc' ? a - b : b - a
                } else if (a === undefined || a === null) {
                    const isBFalsy = b === undefined || b === null
                    if (this.sort === 'asc') return isBFalsy ? 1 : -1
                    else return isBFalsy ? -1 : 1
                } else if (
                    typeof a?.value === 'number' &&
                    typeof b?.value === 'number'
                ) {
                    return this.sort === 'asc'
                        ? a.value - b.value
                        : b.value - a.value
                } else return this.sort === 'asc' ? 1 : -1
            })
        },
        getFilteredData: function (rows) {
            return rows.filter((row) => {
                if (row._detail && row._detail.length > 0) {
                    row._detail = this.getFilteredData(row._detail)
                    if (row._detail.length > 0) return true
                }
                return this.validRow(row)
            })
        },
        getSumRow: function (rows) {
            const getSum = (columnKey) => {
                return _.sumBy(rows, (row) => {
                    const data = row[columnKey]?.value ?? row[columnKey] ?? 0
                    return this.$getPureNumber(data)
                })
            }
            const hasDifferentUnit = (columnKey) => {
                const columnUnit = this.setDefaultColumns[columnKey]?.unit
                const units = rows.map(
                    (row) => row[columnKey]?.unit ?? columnUnit
                )
                return _.uniq(units).length > 1
            }
            return Object.keys(this.setDefaultColumns).reduce(
                (memo, columnKey) => {
                    const column = this.setDefaultColumns[columnKey]
                    if (column.total === 'key') {
                        memo[columnKey] = '合計'
                    }
                    if (hasDifferentUnit(columnKey)) {
                        // 若單位不同，則不加總
                        memo[columnKey] = ''
                        return memo
                    }
                    if (column.total === 'sum') {
                        if (!hasDifferentUnit(columnKey))
                            memo[columnKey] = getSum(columnKey)
                    }
                    if (column.total === 'mean') {
                        let mean
                        if (column.meanBy) {
                            // 用別的key 相除 取平均值
                            const numeratorKey = column.meanBy[0]
                            const denominatorKey = column.meanBy[1]
                            const numeratorSum = getSum(numeratorKey)
                            const denominatorSum = getSum(denominatorKey)
                            mean =
                                denominatorSum > 0
                                    ? numeratorSum / denominatorSum
                                    : 0
                        } else {
                            // 使用資料筆數去計算平均值
                            mean =
                                _.meanBy(rows, (row) => {
                                    const data =
                                        row[columnKey]?.value ??
                                        row[columnKey] ??
                                        0
                                    return this.$getPureNumber(data)
                                }) / 100
                        }
                        memo[columnKey] = this.$toPercentage(mean, 2)
                    }
                    return memo
                },
                { className: { 'sum-row': true } }
            )
        },
        validRow: function (row) {
            const filterCheckedList = this.filterCheckedList
            let pass = true
            Object.keys(row).forEach((columnKey) => {
                if (columnKey !== '_detail') {
                    const cellValue =
                        row[columnKey]?.value?.toString() ??
                        row[columnKey]?.toString() ??
                        ''
                    const notValid =
                        this.isFilterSetList[columnKey] &&
                        filterCheckedList[columnKey] &&
                        !filterCheckedList[columnKey][cellValue]
                    if (notValid) pass = false
                }
            })
            return pass
        },
        setViewportWidth: function () {
            if (this.isShowTable) {
                this.$nextTick(() => {
                    const table = this.$refs.table
                    const row = table?.querySelector('.row')
                    this.viewportWidth = table?.offsetWidth
                    this.rowWidth = row?.offsetWidth
                    this.setViewportPosition()
                })
            }
        },
        setViewportPosition: function () {
            this.$nextTick(() => {
                const table = this.$refs.table
                this.viewportPositionX = table?.scrollLeft || 0
            })
        },
        getFlattenData: function (rows) {
            return rows.reduce((memo, row) => {
                if (row._detail && row._detail.length > 0) {
                    const detail = this.getFlattenData(row._detail)
                    memo.push(...detail)
                    memo.push(_.omit(row, '_detail'))
                } else {
                    memo.push(row)
                }
                return memo
            }, [])
        },
        initFilterCheckedList: function () {
            this.filterCheckedList = this.filterDefaultList
            this.flattenData.forEach((row) => {
                this.columns.forEach((column) => {
                    const columnKey = this.$getColumnKey(column.key) // alias
                    const value =
                        row[columnKey]?.value?.toString() ??
                        row[columnKey]?.toString() ??
                        ''
                    // route的params若有帶外部條件要過濾
                    const searchInfo = this.$store.state.advancedFilter

                    const searchInfoKey = searchInfo?.key
                    const searchInfoValue = searchInfo?.value
                    let isChecked
                    if (columnKey === searchInfoKey) {
                        isChecked = value === searchInfoValue ? true : false
                    } else isChecked = false // (預設不打勾)
                    this.filterCheckedList[columnKey][value] = isChecked
                })
            })
        },
        setToolModal: function (slotProps, key) {
            if (this.isLoading) return
            if (key === 'fullSize') this.isFullSizeTable = true
            if (key === 'columnsSetting') this.isShowColumnsSettingModal = true
            this.$setGaEvent(key, 'click-TableTool')
            if (Object.keys(slotProps).length > 0) {
                slotProps.toggle()
            }
        },
        setFocusRowList: function (index) {
            if (this.isFocusRow) {
                const rowList = _.cloneDeep(this.focusList)
                if (rowList?.includes(index)) {
                    this.focusList = rowList.map(function (item) {
                        if (item !== index) return item
                    })
                } else {
                    this.focusList = [...rowList, index]
                }
            } else {
                return
            }
        },
        setRowHeaderPosition: function () {
            if (this.tableShowData.length === 0) return
            if (this.isUseCard && this.$isMobile()) return
            const tableRect = this.$refs.tableColumn?.getBoundingClientRect()
            if (tableRect?.height <= 0) return
            const tableArrows = document.querySelectorAll('.table-arrow-icon')
            this.updateTableToTop()
            if (
                window.scrollY >= this.tableToTop &&
                !this.isLoading &&
                !this.isFullSizeTable
            ) {
                // fixing top
                this.$refs.headerRow.$el.classList.add('fixing')
                this.$refs.headerRow.$el.style.top = `${this.webHeaderHeight}px`
                const paddingTop =
                    this.$refs.headerRow.$el.getBoundingClientRect().height ||
                    40
                this.$refs.tableBody.style.paddingTop = `${paddingTop}px`
                // set table header width
                this.$refs.headerRow.$el.style.width = `${this.$refs.table.offsetWidth}px`
                // to sync table & header scroll bar
                this.syncTableHeaderScroll()
                this.$refs.table.addEventListener(
                    'scroll',
                    this.syncTableHeaderScroll
                )
                // fix table arrow
                tableArrows.forEach((arrow) => {
                    arrow.style.position = 'fixed'
                    arrow.style.top = `calc(50% - ${arrow.offsetHeight / 2}px)`
                    if (arrow.classList.contains('left')) {
                        let left = tableRect.left - 35
                        arrow.style.left = left > 0 ? `${left}px` : '1%'
                    } else {
                        let right = tableRect.right - arrow.offsetWidth + 35
                        right + arrow.offsetWidth < window.innerWidth
                            ? (arrow.style.left = `${right}px`)
                            : (arrow.style.right = '1%')
                    }
                })
            } else {
                if (this.$refs.headerRow) {
                    this.$refs.headerRow.$el.style.top = 0
                    this.$refs.headerRow.$el.style.width = ''
                }
                if (this.$refs.tableBody)
                    this.$refs.tableBody.style.paddingTop = 0
                this.$refs.headerRow?.$el.classList.remove('fixing')
                this.$refs.table?.removeEventListener(
                    'scroll',
                    this.syncTableHeaderScroll
                )
                tableArrows.forEach((arrow) => {
                    arrow.style.position = ''
                    arrow.style.top = ''
                    arrow.style.left = ''
                    arrow.style.right = ''
                })
            }
        },
        updateTableToTop: function () {
            this.tableToTop =
                window.scrollY +
                this.$refs?.tableColumn?.getBoundingClientRect().top -
                this.webHeaderHeight
        },
        syncTableHeaderScroll() {
            if (this.isLoading) return
            if (this.$refs.headerRow)
                this.$refs.headerRow.$el.scrollLeft = this.$refs.table.scrollLeft
        },
        resizeUpdate: function () {
            if (this.$refs.headerRow)
                this.$refs.headerRow.$el.style.width = `${this.$refs.table.offsetWidth}px`
            this.syncTableHeaderScroll()
            this.setRowHeaderPosition()
        },
        scrollLeft: function () {
            if (!this.canScrollLeft) return
            this.viewportPositionX -= 120
        },
        scrollRight: function () {
            if (!this.canScrollRight) return
            this.viewportPositionX += 120
        }
    },
    computed: {
        dummyData: function () {
            const row = Object.keys(this.columnConfigValue).reduce(
                (memo, colKey) => {
                    memo[colKey] = ''
                    return memo
                },
                {}
            )
            return Array(10).fill(row)
        },
        headerRow: function () {
            return Object.keys(this.columnConfigValue).reduce((memo, key) => {
                memo[key] = this.columnConfigValue[key].label
                return memo
            }, {})
        },
        columns: function () {
            return Object.keys(this.columnConfigValue)
                .reduce((memo, colKey) => {
                    const column = this.columnConfigValue[colKey]
                    memo.push({
                        key: colKey,
                        isVisible: column.isVisible ?? true, // isVisible應預設為true，有設定false才隱藏
                        ...column
                    })
                    return memo
                }, [])
                .sort((colA, colB) => colA.seq - colB.seq)
                .sort((colA, colB) => {
                    if (!colA.isVisible && colB.isVisible) return 1
                    if (colA.isVisible && !colB.isVisible) return -1
                    else return 0
                })
        },
        mobileToolTips: function () {
            let toolTipsList = []
            for (let column in this.columnConfig) {
                if (
                    'toolTips' in this.columnConfig[column] &&
                    this.columnConfig[column].toolTips.content.mobile !== null
                ) {
                    toolTipsList.push(
                        this.columnConfig[column].toolTips.content.mobile
                    )
                }
            }
            return toolTipsList
        },
        tableShowData: function () {
            if (this.isLoading) return this.dummyData
            if (this.rows.length === 0) return []
            let dataset = this.getFilteredData(_.cloneDeep(this.rows))
            const sumRow = this.getSumRow(dataset)

            if (this.sort !== 'normal') {
                dataset = this.getSortedData(dataset)
            }
            if (this.isPagination) {
                dataset = this.getPaginationData(dataset)
            }
            if (this.shouldShowSumRow) {
                dataset = [sumRow, ...dataset]
            }
            return dataset
        },
        excelData: function () {
            if (this.rows.length === 0) return []
            let dataset = this.getFilteredData(_.cloneDeep(this.rows))
            const sumRow = this.getSumRow(dataset)

            if (this.sort !== 'normal') {
                dataset = this.getSortedData(dataset)
            }
            if (this.shouldShowSumRow) {
                dataset = [sumRow, ...dataset]
            }
            return dataset
        },
        filterDefaultList: function () {
            return this.columns.reduce((memo, column) => {
                const columnKey = this.$getColumnKey(column.key)
                memo[columnKey] = {}
                return memo
            }, {})
        },
        paginationTotalPage: function () {
            return Math.ceil(this.pagination.rowCount / this.pagination.perPage)
        },
        flattenData: function () {
            if (this.rows?.length > 0) {
                return this.getFlattenData(this.rows)
            } else {
                return []
            }
        },
        shouldShowSort: function () {
            // 即使不顯示欄位還是可以開放排序
            return Object.values(this.setDefaultColumns).some(
                (col) => col.sortEnabled
            )
        },
        shouldShowFilter: function () {
            // 即使不顯示欄位還是可以開放過濾
            return Object.values(this.setDefaultColumns).some(
                (col) => col.isFilterable
            )
        },
        shouldShowSumRow: function () {
            // 若要顯示的欄位都沒有需要加總的則不顯示
            return Object.values(this.columnConfigValue).some(
                (column) => column.total
            )
        },
        isShowTableTool: function () {
            return (
                this.toolbox.length > 0 ||
                this.shouldShowSort ||
                this.shouldShowFilter
            )
        },
        isFilterSetList: function () {
            const filterCheckedList = this.filterCheckedList
            // 紀錄哪些column有被動過過濾，若過濾有alias欄位，key仍然要帶原本的
            return this.columns.reduce((memo, column) => {
                const columnKey = this.$getColumnKey(column.key)
                const checkedList = filterCheckedList[columnKey] || {}
                const values = Object.values(checkedList)
                const isSet = !values.every((value) => !value)
                memo[columnKey] = isSet
                return memo
            }, {})
        },
        isFilteredEmpty: function () {
            const dataLength = this.shouldShowSumRow ? 1 : 0
            return (
                this.rows.length > 0 && this.tableShowData.length === dataLength
            )
        },
        isShowTable: function () {
            return this.rows.length > 0 || this.isLoading
        },
        toolboxOptions: function () {
            const config = {
                columnSetting: {
                    key: 'columnsSetting',
                    class: 'columns-setting',
                    label: '欄位顯示設定'
                },
                zooming: {
                    key: 'fullSize',
                    class: 'full-size',
                    label: '表格全螢幕'
                }
            }
            return this.toolbox
                .filter((name) => name !== 'excel')
                .map((name) => config[name])
        },
        TableCssProps: function () {
            return {
                '--table-scale': this.tableScaleRatio
            }
        },
        isHiddenSection: function () {
            return this.$store.state.sectionHidden
        },
        setDefaultColumns: function () {
            let column = _.cloneDeep(this.columnConfig)
            this.hiddenColumnDefault.forEach(function (key) {
                column[key].isVisible = false
            })
            return column
        },
        webHeaderHeight: function () {
            return this.$store.state.webHeaderHeight
        },
        isShowSearchMessage: function () {
            return (
                this.rows.length === 0 &&
                !this.isLoading &&
                !this.isAlreadySearch
            )
        }
    },
    watch: {
        setDefaultColumns: {
            handler() {
                this.columnConfigValue = _.cloneDeep(this.setDefaultColumns)
                // 重設欄位排序預設
                this.sort = this.sortDefault.sort
                this.sortBy = this.sortDefault.sortBy
            },
            immediate: true
        },
        isLoading: {
            handler(newVal, oldVal) {
                //使用 isLoading 確認是因重打API更新rows的資料，再讓表格工具回到預設值
                if (newVal === false && oldVal === true) {
                    // 分頁回到第一頁
                    this.pagination.currentPage = 1

                    // 設定左右scroll箭頭是否顯示
                    this.setViewportWidth()

                    // 將所有選項丟給filterChecked
                    this.initFilterCheckedList()
                    this.$store.commit('set', {
                        advancedFilter: {}
                    })
                }
            },
            immediate: true
        },
        tableShowData: function () {
            if (this.isFixedHeader) this.setRowHeaderPosition()
            this.childCollapsedList = Array(this.tableShowData?.length).fill(
                false
            )
            this.isAlreadySearch = true
        },
        'pagination.rowCount': function () {
            if (this.pagination.currentPage > this.paginationTotalPage) {
                this.pagination.currentPage = 1
            }
        },
        viewportPositionX: function () {
            this.$nextTick(() => {
                const table = this.$refs.table
                table.scrollLeft = this.viewportPositionX
                this.canScrollLeft = this.viewportPositionX > 0
                this.canScrollRight =
                    this.viewportWidth + this.viewportPositionX < this.rowWidth
            })
        },
        isFullSizeTable: function () {
            const body = document.querySelector('body')
            const header = document.querySelector('.header')
            this.setRowHeaderPosition()
            if (this.isFullSizeTable) {
                this.$addAllClass(body, 'not-scroll')
                this.$addAllClass(header, 'hide')
            } else {
                this.$removeAllClass(body, 'not-scroll')
                this.$removeAllClass(header, 'hide')
            }
        },
        isHiddenSection: function () {
            setTimeout(() => {
                this.setViewportWidth()
            }, 600)
        },
        filterCheckedList: {
            handler() {
                //快速搜尋完分頁一律回到第一頁
                this.pagination.currentPage = 1
            },
            deep: true
        },
        sortDefault() {
            this.sort = this.sortDefault.sort
            this.sortBy = this.sortDefault.sortBy
        },
        sortBy: {
            handler() {
                this.$emit('update:sortDefault', {
                    sort: this.sort,
                    sortBy: this.sortBy
                })
            }
        },
        sort: {
            handler() {
                this.$emit('update:sortDefault', {
                    sort: this.sort,
                    sortBy: this.sortBy
                })
            }
        },
        //將「欄位顯示設定」保存，建議在頁面檔改以v-model方式傳遞更新資料，範例可參考保單頁面做法
        columns: {
            handler(val) {
                this.$emit('update:columnConfig', val)
            }
        },
        webHeaderHeight: function () {
            this.updateTableToTop()
            this.setRowHeaderPosition()
        }
    },
    data() {
        return {
            columnConfigValue: {},
            //分頁預設
            pagination: {
                rowCount: 0,
                currentPage: 1,
                perPage: 10
            },
            // 表頭的縮放控制
            childCollapsedList: [],
            // 捲軸位置
            viewportPositionX: 0,
            viewportWidth: 0,
            rowWidth: 0,
            isShowFilterModal: false,
            filterColumnKey: '',
            filterCheckedList: {},
            sort: this.sortDefault.sort,
            sortBy: this.sortDefault.sortBy,
            // 全螢幕工具
            isFullSizeTable: false,
            tableScaleRatio: 1,
            // 欄位顯示設定
            isShowColumnsSettingModal: false,
            hiddenCount: 0,
            // headerFixedTop
            tableToTop: 0,
            shouldResetAdvanced: false,
            isAlreadySearch: false,
            canScrollLeft: false,
            canScrollRight: true
        }
    },
    mounted() {
        window.addEventListener('resize', this.setViewportWidth)
        this.setViewportWidth()
        if (this.isFixedHeader) {
            window.addEventListener('resize', this.resizeUpdate)
            window.addEventListener('scroll', this.setRowHeaderPosition)
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.setViewportWidth)
        window.removeEventListener('resize', this.resizeUpdate)
        window.removeEventListener('scroll', this.setRowHeaderPosition)
        if (this.shouldResetAdvanced) {
            this.$store.commit('set', {
                advancedFilter: {}
            })
        }
    },
    activated() {
        this.setRowHeaderPosition()
    }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
    position: relative;
}

.table-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    // max-height: calc(100vh - 180px);
    margin-bottom: 10px;
    // overflow-y: hidden;

    &.card {
        .mobile-header {
            display: none;
        }

        @media screen and (max-width: 576px) {
            max-height: unset;
            .table {
                max-height: unset;
                .mobile-header {
                    display: flex;
                    margin-bottom: 10px;
                }
                :deep(.focus) {
                    &:not(.sum-row) {
                        position: unset;
                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    .table {
        // max-height: calc(100vh - 180px);
        // height: calc(100% + 1px);
        overflow-y: auto;
        z-index: 0;
        :deep(.sum-row) {
            &,
            .cell {
                background-color: $sum-row-grey !important;
                // background-clip: content-box;
            }
        }
    }

    .filtered-empty-row {
        position: sticky;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        padding: 0 20px;
        background-color: $primary-white;
        color: $sixth-black;
        font-size: 14px;
    }
}

.table-tool {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid $ninth-grey;
    margin-bottom: 10px;

    .column-controller-container {
        display: flex;
        float: right;
        flex-grow: 15px;

        & > :deep(.table-sort-container) {
            margin-right: 15px;
        }
        @media screen and (max-width: 576px) {
            :deep(.sort-icon, .dark-filter-icon) {
                width: 16px;
                height: 16px;
            }
            :deep(.dark-filter-icon) {
                width: 16px;
                height: 16px;
            }
        }
    }
    :deep(.columns-setting) {
        position: relative;
        .hidden-columns-bubble {
            text-align: center;
            position: absolute;
            left: 11px;
            top: -1px;
            width: 17px;
            height: 18px;
            border-radius: 100%;
            background-color: $primary-red;
            color: $primary-white;
            font-weight: bold;
            transform: scale(0.9);
            font-size: 12px;
            line-height: 18px;
            transform: scale(0.8);
            padding-left: 1px;
            @media screen and (max-width: 576px) {
                text-align: center;
                left: 19px;
                top: 2px;
                font-size: 14px;
                width: 19px;
                height: 20px;
                line-height: 19px;
            }
        }
    }
}

//全螢幕
.full-size {
    &.table-container {
        background: $primary-grey;
        position: fixed;
        top: 60px;
        left: 0;
        max-height: calc(100vh - 60px);
        overflow: hidden;
        z-index: 7;
        height: 100vh;
        .table {
            :deep(.header) {
                height: fit-content;
            }
            width: fit-content;
            width: calc(100vw / var(--table-scale));
            min-height: calc((100vh - 60px) / var(--table-scale));
            z-index: 7;
            background: $primary-grey;
            // padding: 0px Max(20px, env(safe-area-inset-right, 0)) 0
            //     Max(20px, env(safe-area-inset-left, 0));
            box-sizing: border-box;
            display: grid;
            grid-auto-rows: min-content;
            grid-template-columns: 1fr;
            margin-bottom: 10px;
            overflow-x: auto;
            -webkit-transform: scale(var(--table-scale));
            transform: scale(var(--table-scale));
            transform-origin: left top;
            height: 100vh;
        }
    }

    @media screen and (max-width: 576px) {
        //     padding: 5px Max(10px, env(safe-area-inset-right, 0)) 5px
        //         Max(10px, env(safe-area-inset-left, 0));
    }
}
.table-arrow-icon {
    cursor: pointer;
    position: absolute;
    top: clamp(60px, 40%, 200px);
    width: 40px;
    height: 40px;
    z-index: 5;
    &.left {
        left: -35px;
    }
    &.right {
        transform: rotate(180deg);
        right: -35px;
    }
    @media screen and (min-width: 426px) and (max-width: 1280px) {
        &.left {
            left: -2%;
        }
        &.right {
            right: -2%;
        }
    }
    @media screen and (min-width: 0px) and (max-width: 576px) {
        width: 30px;
        height: 30px;
        &.left {
            left: -4%;
        }
        &.right {
            right: -3%;
        }
    }
}
</style>
